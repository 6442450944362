const SEO = [
	{
		page: "home",
		description:
			"Je suis un développeur backend avec une expertise en PHP. J'ai de l'expérience dans la construction d'applications web évolutives, sécurisées et fiables en utilisant divers frameworks et technologies.",
		keywords: ["Kerian", "Kerian D", "Kerian DELANCRET"],
	},

	{
		page: "about",
		description:
			"Je suis un développeur backend avec une expertise en PHP. J'ai de l'expérience dans la construction d'applications web évolutives, sécurisées et fiables en utilisant divers frameworks et technologies.",
		keywords: ["Kerian", "Kerian D", "Kerian DELANCRET"],
	},

	{
		page: "articles",
		description:
			"Je suis un développeur backend avec une expertise en PHP. J'ai de l'expérience dans la construction d'applications web évolutives, sécurisées et fiables en utilisant divers frameworks et technologies.",
		keywords: ["Kerian", "Kerian D", "Kerian DELANCRET"],
	},

	{
		page: "projects",
		description:
			"Je suis un développeur backend avec une expertise en PHP. J'ai de l'expérience dans la construction d'applications web évolutives, sécurisées et fiables en utilisant divers frameworks et technologies.",
		keywords: ["Kerian", "Kerian D", "Kerian DELANCRET"],
	},

	{
		page: "contact",
		description:
			"Je suis un développeur backend avec une expertise en PHP. J'ai de l'expérience dans la construction d'applications web évolutives, sécurisées et fiables en utilisant divers frameworks et technologies.",
		keywords: ["Kerian", "Kerian D", "Kerian DELANCRET"],
	},
];

export default SEO;

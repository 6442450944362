import React from "react";

function article_1() {
	return {
		date: " 06 Mai 2024",
		title: "L'IA dans la course pour la Formule 1",
		description:
			"Un véhicule autonome piloté par une IA développée à l'Université de technologie de Munich (TUM) a remporté une course de Formule 1 à Abu Dhabi, conçue expressément pour ce type de voitures de course. Evolutions notables par rapport à leurs prédécesseurs : la capacité à « percevoir » l'action et à doubler les concurrents.",
		keywords: [
			"Université de technologie de Munich",
			"A",
			"véhicule autonome",
			"Formule 1",
		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">Ni Max Verstappen, ni Lewis Hamilton. C'est bel et bien une IA à bord d'un véhicule autonome qui a franchi la première la ligne d'arrivée d'une course sur le circuit de Formule 1 d'Abu Dhabi (Émirats arabes unis). Et derrière elle, une équipe de chercheurs de l'Université de technologie de Munich (TUM). Mais, les courses de vitesse avec des voitures contrôlées par l'IA, c'est une histoire déjà ancienne. Des compétitions d'Indycars ont eu lieu à Las Vegas et la Formule E a opposé des voitures électriques autonomes les unes aux autres lors de la Roborace. Mais les deux formats ont fait un flop, car ils étaient trop peu spectaculaires. Les véhicules étaient trop lents et ne pouvaient pas vraiment doubler de manière autonome.

Des défauts que la nouvelle Abu Dhabi Autonomous Racing League (A2RL) veut éviter. Les voitures de course qui ont concouru sont fabriquées en Italie par Dallara et développent 550 ch pour des vitesses de pointe à plus de 300 km/h. L'objectif étant de se rapprocher le plus possible des performances de la Formule 1.

La première manoeuvre de dépassement autonome au monde

Un autre événement, toujours à Abu Dhabi, a confirmé les progrès des véhicules mus par l'IA. Le pilote de Formule 1 russe Daniil Kvyat a affronté l'un d'eux. Bien qu'il ait tenu bon et laissé son adversaire autonome à 10,38 secondes, la course de 45 minutes a clairement montré à quel point ces véhicules autonomes se rapprochent des conducteurs humains.

L'explication ? L'IA tient désormais compte de la vitesse tout comme de l'action. De plus, contrairement aux précédentes courses, quatre voitures pilotées par IA se sont affrontées simultanément durant 16 tours sur la piste de 5 km du circuit de Yas Marina. La voiture TUM a même effectué la première manoeuvre de dépassement autonome au monde sur un circuit de
Formule 1. Dans un autre tour rapide, il a ensuite remporté la victoire.

Un défi logiciel

Les Dallara n'ont pratiquement pas été modifiées à l'exception de l'alternateur plus puissant nécessaire à alimenter l'électronique de bord. Pour qu'elles récupèrent l'image virtuelle complète de leur environnement, elles sont par ailleurs sans surprise bardées de capteurs : sept caméras, trois lidars et quatre radars. Les conditions étaient les mêmes pour toutes les équipes : une Dallara Super Formula SF23 modifiée, la voiture de course décapotable la plus rapide au monde après la Formule 1. Pour les équipes, la véritable compétition s'est jouée au niveau du développement et de l'entraînement de leur IA.

Une tâche d'autant plus complexe qu'aucune intervention ni accès aux véhicules n'étaient autorisés pendant la course. L'IA a donc dû développer une compréhension du niveau d'adhérence des pneus et apprendre à gérer leur température. Il fallait également prédire les manoeuvres de conduite des adversaires pour réussir à les doubler - tout ce qu'un pilote de course humain fait habituellement intuitivement.

Markus Lienkamp, professeur à la chaire d'ingénierie automobile de la TUM, considère ce succès comme une étape importante dans la conduite autonome : « Notre équipe a pu amener le véhicule aux limites de la dynamique de conduite en très peu de temps, en détectant et en dépassant d'autres véhicules. Nous arrivons de mieux en mieux à reproduire le comportement d'un être humain durant la course. » Au total, trois professeurs, dix doctorants et cinq étudiants en master de la chaire d'ingénierie automobile de la TUM ont travaillé sur le système. Et le jeu en valait la chandelle, puisque l'université a aussi emporté un prix de 2,25 millions de dollars.</div>
					<img
						src="https://images.itnewsinfo.com/lmi/articles/grande/000000097174.jpg"
						alt="random"
						className="randImage"
					/>
				</div>
			</React.Fragment>
		),
	};
}

function article_2() {
	return {
		date: "06 Mai 2024",
		title: "Baccarat brise la glace sur sa cyberattaque",
		description:
			"Après une attaque par ransomware en septembre 2023, le DSI de Baccarat est revenu en toute transparence sur cet évènement et l'aide apportée par Orange Cyberdéfense.",
		style: ``,
		keywords: [
			"ransomware",
			"Baccarat",
			"Orange Cyberdéfense.",
			"DSI",
		],
		body: (
			<React.Fragment>
				<h1>A l’occasion du FIC 2024 à Lille, Stéphane Cordier, directeur de la transformation et DSI du groupe Baccarat a évoqué la cyberattaque menée en septembre 2023 par le groupe de ransomware Blackbasta contre la société. Avant de rentrer dans le détail, le dirigeant revient sur l’histoire et le profil de l’entreprise. Cette dernière œuvre dans la cristallerie de luxe depuis 260 ans avec une présence partout dans le monde (Asie, Etats-Unis et Europe). Baccarat emploie 1 400 personnes dont 700...
</h1>
			</React.Fragment>
		),
	};
}

const myArticles = [article_1, article_2];

export default myArticles;

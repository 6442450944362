const INFO = {
	main: {
		title: "Portfolio Kerian",
		name: "Delancret K.",
		email: "kerian.delancret@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/keriandel",
		github: "https://github.com/kerianed",
		linkedin: "https://www.linkedin.com/in/k%C3%A9rian-delancret-a43518216/",
		instagram: "https://www.instagram.com/krndlct/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://fr-fr.facebook.com/people/Kerian-Delancret/100002609762602",
	},

	homepage: {
		title: "Développeur Full Stack & mobile.",
		description:
			"Je suis un développeur back-end et front-end avec une expertise en PHP. J'ai de l'expérience dans la construction d'applications web évolutives, sécurisées et fiables en utilisant différents frameworks et technologies. J'apprécie apprendre de nouvelles compétences. Je suis passionné par le code de haute qualité qui suit les meilleures pratiques et les normes de l'industrie. Je suis toujours à la recherche de nouveaux défis et d'opportunités pour évoluer en tant que développeur.",
	},

	about: {
		title: "Je m'appelle Kérian et je vis en France, où je conçois l'avenir.",
		description:
			"J'ai travaillé sur une variété de projets et je suis fier des progrès que j'ai réalisés. Beaucoup de ses projets sont open-source et disponibles pour que d'autres les explorent et y contribuent. Si vous êtes intéressé par l'un des projets sur lesquels j'ai travaillé, n'hésitez pas à consulter le code et à suggérer toutes les améliorations ou les ajouts que vous pourriez avoir en tête. Collaborer avec d'autres est un excellent moyen d'apprendre et de progresser, et je suis toujours ouvert aux nouvelles idées et aux retours d'expérience.",
	},

	articles: {
		title: "Je suis passionné par repousser les limites de ce qui est possible et inspirer la prochaine génération d'innovateurs.",
		description:
			"Une liste de mes veilles technologiques.",
	},

	projects: [
		{
			title: "Answer",
			description:
				"J'ai également contribué à la création d'un site similaire à Reddit en Symfony et Twig, en élaborant une plateforme communautaire dynamique où les utilisateurs pouvaient partager du contenu varié, participer à des discussions animées et voter pour les contributions les plus pertinentes",
			logo: "https://cdn2.vectorstock.com/i/1000x1000/48/51/logo-letter-a-blue-glowing-vector-22854851.jpg",
			linkText: "",
			link: "https://www.reddit.com/",
		},

		{
			title: "S&box",
			description:
				"J'ai travaillé sur un projet de création de jeu sur s&box, où j'ai conçu un univers interactif et immersif exploitant les fonctionnalités de la plateforme pour offrir une expérience de jeu captivante et innovante",
			logo: "https://upload.wikimedia.org/wikipedia/commons/4/4c/S%26box-Logo.png",
			linkText: "",
			link: "https://sbox.game/",
		},

		{
			title: "Habbok",
			description:
				"j'ai travaillé sur un projet similaire à Habbo, où j'ai participé à la création d'un espace virtuel social offrant aux utilisateurs la possibilité d'interagir, de personnaliser leur avatar et de participer à une variété d'activités ludiques",
			logo: "https://pbs.twimg.com/profile_images/1686313339255746560/8CX73KHq_400x400.jpg",
			linkText: "",
			link: "https://habbozone.fr/",
		},

		{
			title: "API",
			description:
				" j'ai développé une API REST avec Symfony, créant une interface robuste et sécurisée pour permettre une communication fluide entre différentes applications, tout en garantissant la confidentialité et l'intégrité des données échangées",
			logo: "https://media.printables.com/media/prints/825304/images/6367971_fc2f9b61-ecea-4744-9ac0-6dbbc4dfe20a_337a4916-908f-41e4-9146-fa05e6839ff6/thumbs/inside/1280x960/jpg/symfony.webp",
			linkText: "",
			link: "#",
		},

		{
			title: "MonMedecin",
			description:
				" j'ai contribué à la création d'une solution de télémédecine en ligne, permettant aux patients de consulter des professionnels de santé à distance, de prendre rendez-vous facilement et de bénéficier de services de téléconsultation et de suivi médical, améliorant ainsi l'accessibilité et la qualité des soins de santé",
			logo: "https://media.licdn.com/dms/image/D4E0BAQGFLuUPtTsJHQ/company-logo_200_200/0/1696426105765/monmdecin_logo?e=2147483647&v=beta&t=77kH4o4y0MOVdvfaWBnEsJzeebYd-czX5DK_9kyR1hg",
			linkText: "",
			link: "https://landing.monmedecin.org/",
		},
	],
};

export default INFO;
